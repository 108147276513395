<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" >
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <transition name="fade">
      <PageLoading v-if="PageLoading"></PageLoading>
    </transition>
    <MDBRow>
      <MDBCol class="mb-1">
        <input type="month" v-model="start" class="form-control" format="YYYY-MM">
      </MDBCol>
      <MDBCol md="2">
        <MDBInput :label="$t('COMMON.DEALEREMAIL')" :placeholder="$t('COMMON.DEALEREMAIL')" v-model="dealer" />
      </MDBCol>
      <MDBCol class="mb-2 text-end">
        <MDBBtn color="info" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>  
        <MDBBtn color="warning" @click="Export">{{ $t("COMMON.EXPORT") }}</MDBBtn>  
      </MDBCol>
    </MDBRow>
    <MDBTable
      class="table datatable-table"
      responsive
    >
      <thead>
        <tr>
          <th v-for="(column ,k) in headers" scope="col" :key="k" :width="column.width" :class="column.class">
            {{ column.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="desserts.length > 0">
          <tr v-for="(row ,k) in desserts" :key="k" >
            <td scope="row" v-for="(column ,i) in headers" :key="i" :class="column.class">
              <template v-if="column.value == 'dealer'">
                {{ (row.dealer)?(row.dealer.last_name + row.dealer.first_name):"" }}
              </template>
              <template v-else-if="column.value == 'no'">
                {{ k+1 }}
              </template>
              <template v-else-if="column.value == 'settings'">
                NTD/{{ (row.dealer)?((row.dealer.settings.find(item=>item.key=='currency'))?((row.dealer.settings.find(item=>item.key=='currency').value!="TWD")?row.dealer.settings.find(item=>item.key=='currency').value:"NTD"):"NTD"):"" }}
              </template>
              <template v-else-if="column.value == 'dealer_id'">
                <a href="javascipt:void(0)" @click="ExportDeatil(row.dealer.uuid)">下載</a>
              </template>
              <template v-else-if="column.value == 'exchange'">
                {{ this.getCurrencyList.find(item=>item.currency==((row.dealer)?((row.dealer.settings.find(item=>item.key=='currency'))?row.dealer.settings.find(item=>item.key=='currency').value:"TWD"):"")).exchange_rate }}
              </template>
              <template v-else>
                {{ this.HandleData(column.value, row) }}
              </template>
            </td>
          </tr>	
        </template>
        <tr v-if="desserts.length == 0">
          <td :colspan="headers.length" class="text-center">無資料</td>
        </tr>
      </tbody>
    </MDBTable>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBContainer, MDBCard, MDBCardBody, MDBBtn, MDBRow, MDBCol, MDBInput,MDBTable } from 'mdb-vue-ui-kit';
import Breadcrumbs from '@/components/Breadcrumbs';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';
import JwtService from "@/core/services/jwt.service";
import { mapGetters } from 'vuex';

export default {
  name: "DealerReport",
  components: {
		MDBContainer,
		Breadcrumbs,
    MDBCard,
    MDBCardBody,
    PageLoading,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBTable,
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(false);
    const loading = ref(true);
    // const exportLoading = ref(false);
    const total = ref(0);
    const Common = CommonService;
    const desserts = ref([]);
    const tfoot = ref([]);
    const date = new Date();
    const today = `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2,0)}`;
    const start = ref(today);
    const member = ref("");
    const dealer = ref("");
    const order = ref("");

    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: ['desc'],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,

    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",
        width:50
      },
      { text: "計算日期", value: "date",width:112 },
      { text: "經銷商名稱", value: "dealer",width:150 },
      { text: "訂單筆數", value: "count",width:112 },
      { text: "總計NTD", value: "paid_amount",width:112 },
      { text: "幣種", value: "settings",width:112 },
      { text: "匯率", value: "exchange" },
      { text: "獲利", value: "amount",width:112 },
      { text: "明細", value: "dealer_id",width:112 },
    ]);

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/DealerReport",
          {
            params: {
              month: `${start.value}`,
              dealer: dealer.value
            }
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data,
            );
            resolve(response);
          }
        });
      });
    };

    const Export = function() {
      var obj = {
        month: `${start.value}`,
        token: JwtService.getToken(),
      };
      window.open(`${process.env.VUE_APP_API_URL}/Admin/v1/DealerReport/Export?${Object.entries(obj).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join("&")}`)
    };

    const ExportDeatil = function(uuid) {
      var obj = {
        action: 'detail',
        uuid: uuid,
        month: `${start.value}`,
        token: JwtService.getToken(),
      };
      window.open(`${process.env.VUE_APP_API_URL}/Admin/v1/DealerReport/Export?${Object.entries(obj).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join("&")}`)
    };

    const HandleData = function(key, row) {
			let tmp = key.split(".");
			let result = row;
			for (let k in tmp) {
				result = result[tmp[k]];
			}
			return result;
		};

		return {
      PageLoading,
      loading,
      total,
      Common,
      options,
      headers,
      desserts,
      GetTable,
      tfoot,
      start,
      member,
      dealer,
      order,
      Export,
      HandleData,
      ExportDeatil,
    };
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters([
      "getCurrencyList",
    ]),
  },
  mounted() {
    Promise.all([
      this.$store.dispatch("currencyList"),
    ])
    .then(() => {
      this.GetTable(),
      this.PageLoading = false;
    })
    
  }
};
</script>